.page {
    border: 2px #1E0E40 solid;
    border-radius: 36px;
    margin: 0 10px;
    background-color: #F2F2F2;

    display: flex;
    flex-direction: column;
    min-height: 144px;
    gap: 10px;
    padding-bottom: 20px;
}