.wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
}

.page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}