.box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.img {
    width: 30px;
    margin: 10px;
}