.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 200px;
}

.randomPage {
    width: 100%;
    height: 100%;
}

html {
    height: 100%;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.boardGameName {
    font-size: 30px;
}