.item {
    display: flex;
    padding: 8px 18px 8px 18px;
    margin: 0;
    border-bottom: solid;
    border-top-style: solid;

    border-color: #8C0D48;
    border-width: 3px;
    text-decoration: none;
    color: white;
}

.item:hover {
    background-color: #8C0D48;
}

.item:active {
    background-color: #BF0B3B;
}
