.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;

    border-style: solid;
    border-radius: 36px;
    border-color: #1E0E40;

    background-color: #F2F2F2;
    padding: 5px 36px;
}

.input {
    border-style: hidden;
    background-color: #F2F2F2;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color: #1E0E40;
    width: 80%;
}

.text {
    text-align: start;
    padding: 0 10px;
    border: hidden #1E0E40 2px;
    border-bottom-style: solid;
    width: fit-content;
}
