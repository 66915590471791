.page {
display: flex;
    justify-content: center;
}

.statBlock{
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: #1E0E40 solid 2px;
    border-radius: 36px;
    margin: 10px;
    padding-top: 10px;
}

.columnBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    padding: 20px 10px 10px 10px;

    border-top: #1E0E40 solid 2px;
}