.button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 12px;
    text-align: center;
    border-radius: 20px;
    border-width: 0;
    color: white;
    background-color: #1E0E40;
    text-decoration: none;
}

.button:hover {
    background-color: #8C0D48;
}

.button:active {
    background-color: #BF0B3B;
}