.modalWindow {
    border: #1E0E40 solid 2px;
    border-radius: 36px;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}